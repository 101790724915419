@import "breakpoints"; @import "colors";
.PopupWrapper {
}

.DialogPopup {
  & :global {
    .MuiPaper-root {
      padding: 48px;
      border-radius: 12px;
    }
    .MuiDialog-closeButtonWrap {
      display: contents;
    }
  }
}

.DialogTitle {
  font-size: 25px;
  font-weight: 700;
  line-height: 125%;
  margin-bottom: 8px;
}

.DialogDescription {
  font-size: 16px;
  font-weight: 450;
  line-height: 150%;
}

.DialogButtonWrapper {
  margin-top: 24px;
  text-align: center;
}
