@import "breakpoints"; @import "colors";
.CTAButton {
  display: contents;
  width: unset !important;

  & :global .MuiTypography-button {
    display: flex;
    align-items: center;
  }
}

.CTAButton--disabled {
  & :global button {
    opacity: 1 !important;
    pointer-events: none;
  }
}

.CTAButton__Spinner {
  width: 24px !important;
  height: 24px !important;
}

.CTAIcon {
  transform: translateY(-1px);
}

.CTAInner {
  display: flex;
  align-items: center;
  gap: 8px;
}

.CTAButton--secondary {
  &:global.MuiButtonBase-root:hover {
    background-color: #fae4d3 !important;
  }
  &:global.MuiButtonBase-root:hover :global .MuiButton-label {
    color: #e87722;
  }
  &:global.MuiButtonBase-root:hover :global path {
    fill: #e87722 !important;
  }
}

.CTAButton--secondary-white-bg {
}
.CTAButton--secondary-orange-bg {
  &:global.MuiButtonBase-root {
    background-color: #e87722;
    border-color: #fff !important;
  }
  & :global .MuiButton-label {
    color: #fff;
  }
  & :global path {
    fill: #fff !important;
  }
}
